<template>
  <b-tabs
    horizontal
    content-class="col-12 col-md-12 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-12 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab
      :active="activeKegiatan"
    >

      <!-- title -->
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Kegiatan</span>
      </template>
      <!-- v-if="options.proyek"
      :data-proyek="options.proyek" -->
      <kegiatan
        @clicked="simpanKegiatan"
        :tagPublicOptions="tagPublicOptions.options"
        :tagAdminOptions="tagAdminOptions.options"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- Koordinator -->
    <b-tab v-if="this.$route.params.id && $can('tabkoordinator', 'kegiatan')">
      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Koordinator</span>
      </template>

      <koordinator/>
    </b-tab>

    <!-- User -->
    <b-tab
      v-if="this.$route.params.id && $can('tabuser', 'kegiatan')"
      :active="activeUser"
    >

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">User</span>
      </template>

      <user/>
    </b-tab>

    <b-tab
      v-if="this.$route.params.id && $can('tablink', 'kegiatan')"
    >
      <!-- title -->
      <template #title>
        <feather-icon
          icon="Link2Icon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Link</span>
      </template>

      <form-link/>
    </b-tab>
    <b-toast />
  </b-tabs>
</template>

<script>
import { BTabs, BTab, BToast } from 'bootstrap-vue'
import router from '@/router'
import {
  getAllProyek,
} from '@/api/proyek'
import {
  insKegiatan, ubahKegiatan, uploadFileKegiatan,
} from '@/api/kegiatan'
import {
  getTag,
} from '@/connection-api/master'
import Kegiatan from './form/Kegiatan.vue'
import Koordinator from './form/Koordinator.vue'
import User from './form/User.vue'
import FormLink from './form/Link.vue'

export default {
  components: {
    BTabs,
    BTab,
    Kegiatan,
    Koordinator,
    User,
    FormLink,
    BToast,
  },
  data() {
    return {
      options: {},
      activeKegiatan: true,
      activeUser: false,
      tagAdminOptions: {
        selected: 'id',
        options: [
        ],
      },
      tagPublicOptions: {
        selected: 'id',
        options: [
        ],
      },
    }
  },
  async mounted() {
    const tagPublic = await getTag(2)
    this.mappingSelectOption(tagPublic.data, this.tagPublicOptions.options)
    const tagAdmin = await getTag(1)
    this.mappingSelectOption(tagAdmin.data, this.tagAdminOptions.options)

    if (this.$router.currentRoute.query.userId) {
      this.activeKegiatan = false
      this.activeUser = true
    }
    try {
      const response = await getAllProyek(this.dataMasterBiodata)
      this.options.proyek = response.data
    } catch (error) {
      // console.log(error.response.data)
    }
  },
  methods: {
    mappingSelectOption(master, name) {
      if (master.length > 0) {
        master.forEach(element => {
          name.push({
            label: element.nama,
            value: element.id,
          })
        })
      }
    },
    async simpanKegiatan(value) {
      if (value.File) {
        // Update File
        const responseUpload = await uploadFileKegiatan(value.File, 'kegiatan', event => {
          this.progress = Math.round((100 * event.loaded) / event.total)
        })
        const imagefilename = responseUpload.data.filename

        if (this.$route.params.id) {
          try {
            // value.proyek_id = value.select_proyek.selected
            value.id = this.$route.params.id
            value.img = imagefilename
            delete value.File

            const responseUpdate = await ubahKegiatan(value)
            this.makeToast('success', 'Ubah Kegiatan Berhasil!')
          } catch (error) {
            this.makeToast('danger', error.response.data)
            this.show = false
          }
        } else {
          try {
            // value.proyek_id = value.select_proyek.selected
            value.img = imagefilename
            delete value.File
            // console.log(value)
            const responseInsert = await insKegiatan(value)
            // console.log(responseInsert)
            if (value.kegiatan_id) {
              this.makeToast('success', 'Ubah Kegiatan Berhasil!')
            } else {
              this.makeToast('success', 'Tambah Kegiatan Berhasil!')
              setTimeout(() => this.$router.push({ name: 'pages-kegiatan-ubah', params: { id: responseInsert.data.kegiatan_id } }), 500)
            }
          } catch (error) {
            this.makeToast('danger', error.response.data.data)
            this.show = false
          }
        }
      } else {
        try {
          // value.proyek_id = value.select_proyek.selected
          const response = await insKegiatan(value)
          // console.log(response)
          if (value.kegiatan_id) {
            this.makeToast('success', 'Ubah Kegiatan Berhasil!')
          } else {
            this.makeToast('success', 'Tambah Kegiatan Berhasil!')
            setTimeout(() => this.$router.push({ name: 'pages-kegiatan-ubah', params: { id: response.data.kegiatan_id } }), 500)
          }
        } catch (error) {
          this.makeToast('danger', error.response.data.data)
          this.show = false
        }
      }
    },
    makeToast(variant = null, message = null) {
      this.$bvToast.toast(message, {
        title: 'Kegiatan',
        variant,
        solid: true,
        autoHideDelay: 10000,
        enableHtml: true,
        allowHtml: true,
      })
    },
  },
  beforeCreate() {
    // this.$http.get('/kegiatan/data').then(res => { this.options = res.data })
  },
}
</script>
