<template>
  <b-overlay
    :show="busy"
    rounded="sm"
  >
    <b-overlay
      :show="show"
      no-wrap
    />
    <div v-if="keyValue">
      <b-card-code title="Daftar Link">
          <b-form
            ref="form"
            :style="{height: trHeight}"
            class="repeater-form"
          >
            <!-- Row Loop -->
            <b-row
              v-for="(item, index) in items"
              :id="item.id"
              :key="index"
              ref="row"
            >
              <!-- Item Name -->
              <b-col md="4">
                <b-form-group
                  label="Aplikasi"
                  label-for="item-name"
                >
                  <!-- :options="aplikasiOption" -->
                  <v-select
                    v-if="item.id"
                    id="statusList"
                    v-model="item.nama_aplikasi"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="localData.options"
                  >
                    <template v-slot:option="option">
                      {{ option.name }}
                    </template>
                  </v-select>
                  <v-select
                    v-else
                    id="statusList"
                    v-model="localData.data.selected[index]"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="localData.options"
                  >
                    <template v-slot:option="option">
                      {{ option.name }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label="Link"
                  label-for="item-name"
                >
                  <b-form-input
                    v-if="item.id"
                    v-model="mountData.data[index].link"
                    type="text"
                    placeholder="https://www.link.com/id/9238493281738"
                  />
                  <b-form-input
                    v-else
                    v-model="localData.data.link[index]"
                    type="text"
                    placeholder="https://www.link.com/id/9238493281738"
                  />
                </b-form-group>
              </b-col>

              <!-- Cost -->
              <b-col md="3">
                <b-form-group
                  label="Nama"
                >
                  <b-form-input
                    v-if="item.id"
                    v-model="mountData.data[index].nama"
                    type="text"
                    placeholder="Kursus Menjahit"
                  />
                  <b-form-input
                    v-else
                    v-model="localData.data.nama[index]"
                    type="text"
                    placeholder="Kursus Menjahit"
                  />
                </b-form-group>
              </b-col>

              <b-col md="2">
                <b-form-group
                  label="ID Aplikasi"
                >
                  <b-form-input
                    v-if="item.id"
                    v-model="mountData.data[index].ref_aplikasi_id"
                    type="text"
                    placeholder="#1"
                  />
                  <b-form-input
                    v-else
                    v-model="localData.data.ref_aplikasi_id[index]"
                    type="text"
                    placeholder="#1"
                  />
                </b-form-group>
              </b-col>
              <!-- Remove Button -->
              <b-col
                lg="6"
                md="6"
                class="mb-50"
              >
                <b-button
                  v-if="item.id"
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-primary"
                  class="mt-0 mt-md-2 mr-1"
                  @click="addItem(index, 'edit')"
                >
                  <feather-icon
                    icon="SaveIcon"
                    class="mr-25"
                  />
                  <span>Simpan</span>
                </b-button>
                <b-button
                  v-else
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-primary"
                  class="mt-0 mt-md-2 mr-1"
                  @click="addItem(index, 'new')"
                >
                  <feather-icon
                    icon="SaveIcon"
                    class="mr-25"
                  />
                  <span>Simpan</span>
                </b-button>
                <b-button
                  v-if="item.id"
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  @click="removeDataItem(index)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-25"
                  />
                  <span>Hapus</span>
                </b-button>
                <b-button
                  v-else
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  @click="removeItem(index)"
                >
                  <feather-icon
                    icon="XIcon"
                    class="mr-25"
                  />
                  <span>Hapus</span>
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>
          </b-form>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="repeateAgain"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-25"
          />
          <span>Tambah Link</span>
        </b-button>
      </b-card-code>
      <b-toast />
    </div>
    <template v-slot:overlay>
      <div
        v-if="processing"
        class="text-center p-4 bg-primary text-light rounded"
      >
        <b-icon
          icon="cloud-upload"
          font-scale="4"
        />
        <div class="mb-3">
          Processing...
        </div>
        <b-spinner class="align-middle"></b-spinner>
      </div>
      <div
        v-else
        ref="dialog"
        tabindex="-1"
        role="dialog"
        aria-modal="false"
        aria-labelledby="form-confirm-label"
        class="text-center p-3"
      >
        <p><strong id="form-confirm-label">Apakah Anda Yakin?</strong></p>
        <div class="d-flex">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-danger"
            class="mr-3"
            @click="onCancel"
          >
            Tidak
          </b-button>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="outline-success"
            @click="onOK"
          >
            OK
          </b-button>
        </div>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton, BToast, BOverlay,
} from 'bootstrap-vue'
import {
  getListLinkKegiatan, getAllAplikasi, insLinkKegiatan, ubahLinkKegiatan, delLinkKegiatan,
} from '@/api/kegiatan'
import vSelect from 'vue-select'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCardCode,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    vSelect,
    BToast,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      busy: false,
      show: false,
      keyValue: true,
      items: [],
      processing: null,
      localData: {
        data: {
          selected: [],
          link: [],
          nama: [],
          ref_aplikasi_id: [],
        },
        options: [],
      },
      mountData: {
        data: [],
      },
      dataRemove: null,
    }
  },
  async mounted() {
    this.keyValue = false
    this.loadLinkKegiatan()
    this.loadAllAplikasi()
    this.keyValue = true
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    async loadLinkKegiatan() {
      this.items = []
      const responseLinkKegiatan = await getListLinkKegiatan(this.$route.params.id)
      const dataTemp = responseLinkKegiatan.data.data
      dataTemp.forEach(element => {
        this.items.push(element)
      })

      this.mountData.data = dataTemp
      this.mountData.data.forEach((value, index) => {
        this.mountData.data[index].selectedOption = {
          id: value.aplikasi_id,
          name: value.nama_aplikasi,
        }
      })
    },
    async loadAllAplikasi() {
      let responseAllAplikasi = await getAllAplikasi()
      responseAllAplikasi = responseAllAplikasi.data.data
      responseAllAplikasi.forEach(element => {
        this.localData.options.push({
          id: element.id, name: element.nama,
        })
      })
    },
    repeateAgain() {
      this.items.push({
        id: null,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeDataItem(index) {
      this.busy = true
      this.dataRemove = this.items[index].id
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    async addItem(index, status) {
      if (status === 'new') {
        const dataTemp = this.getSelectedData(this.localData.data, index)
        try {
          const response = await insLinkKegiatan(dataTemp)
          this.loadLinkKegiatan()
          this.makeToast('success', 'Tambah Data Berhasil!')
        } catch (error) {
          this.makeToast('danger', JSON.stringify(error.response.data))
        }
      } else {
        const dataTemp = {}
        dataTemp.id = this.mountData.data[index].id
        dataTemp.user_id = this.mountData.data[index].user_id
        dataTemp.aplikasi_id = this.items[index].nama_aplikasi.id
        dataTemp.kegiatan_id = this.mountData.data[index].kegiatan_id
        dataTemp.link = this.mountData.data[index].link
        dataTemp.nama = this.mountData.data[index].nama
        dataTemp.ref_aplikasi_id = this.mountData.data[index].ref_aplikasi_id
        await ubahLinkKegiatan(dataTemp)
        this.makeToast('success', 'Ubah Data Berhasil!')
      }
    },
    getSelectedData(dataPool, index) {
      const formData = new FormData()
      formData.append('kegiatan_id', this.$route.params.id)

      dataPool.ref_aplikasi_id.forEach((value, indexFor) => {
        if (indexFor === index) {
          formData.append('ref_aplikasi_id', value)
        }
      })
      dataPool.link.forEach((value, indexFor) => {
        if (indexFor === index) {
          formData.append('link', value)
        }
      })
      dataPool.nama.forEach((value, indexFor) => {
        if (indexFor === index) {
          formData.append('nama', value)
        }
      })
      dataPool.selected.forEach((value, indexFor) => {
        if (indexFor === index) {
          formData.append('aplikasi_id', value.id)
        }
      })
      return formData
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    onCancel() {
      this.busy = false
    },
    async onOK() {
      try {
        this.busy = false
        this.show = true
        await delLinkKegiatan(this.$route.params.id, this.dataRemove, 0)
        this.show = false
        this.loadLinkKegiatan()
        this.makeToast('success', 'Hapus Link Kegiatan Berhasil!')
      } catch (error) {
        this.makeToast('danger', JSON.stringify(error.response.data))
        this.show = false
      }
    },
    makeToast(variant = null, message = null) {
      this.$bvToast.toast(message, {
        title: 'Kegiatan',
        variant,
        solid: true,
        autoHideDelay: 10000,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
// .repeater-form {
//   overflow: hidden;
//   transition: .35s height;
// }
</style>
