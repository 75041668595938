<template>
  <b-overlay
    :show="busy"
    rounded="sm"
  >
    <b-overlay
      :show="show"
      no-wrap
    />

  <b-card-code title="Daftar Koordinator">
      <b-row>
        <b-col sm="12" class="d-flex justify-content-end mb-2">
          <b-button
            v-b-modal.modal-1
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
          >
            Tambah
          </b-button>
        </b-col>
      </b-row>

      <!-- table -->
      <vue-good-table
        mode="remote"
        :columns="columns"
        :rows="rows"
        :totalRows="rowsTotal"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :pagination-options="{
          enabled: true,
        }"
        @on-page-change="onPageChangeMain"
        @on-sort-change="onSortChangeMain"
        @on-column-filter="onColumnFilterMain"
        @on-per-page-change="onPerPageChangeMain"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Name -->
          <div
            v-if="props.column.field === 'name'"
            class="text-nowrap"
          >
            <b-avatar
              :src="avatar"
              class="mx-1"
            />
            <span class="text-nowrap">{{ props.row.name }}</span>
          </div>

          <div
            v-else-if="props.column.field === 'biodata.email'"
            class="text-nowrap"
          >
            <div v-if="props.row.biodata">
              {{ props.row.biodata.email }}
            </div>
          </div>

          <span v-else-if="props.column.field === 'status_approval_id'">
            <b-badge :variant="statusVariant(props.row.status_approval_id)">
              {{ props.row.status_approval }}
            </b-badge>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  @click="setujuUser(props.index, props.row.id, props.row)">
                  <feather-icon
                    icon="CheckCircleIcon"
                    class="mr-50"
                  />
                  <span>Setuju</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="tolakUser(props.index, props.row.id, props.row)">
                  <feather-icon
                    icon="XIcon"
                    class="mr-50"
                  />
                  <span>Tolak</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="deleteKoordinator(props.index, props.row.id, props.row)">
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Hapus</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
          <!-- <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  @click="deleteKoordinator(props.index, props.row.id, props.row)">
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Hapus</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span> -->

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <!-- <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap "> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template> -->
      </vue-good-table>
      <b-modal id="modal-1" size="lg" title="Daftar User Koordinator" hide-footer>
        <vue-good-table
          mode="remote"
          :columns="columnsSub"
          :rows="rowsSub"
          :totalRows="rowsSubTotal"
          :rtl="direction"
          :pagination-options="{
            enabled: true,
          }"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Name -->
            <div
              v-if="props.column.field === 'fullName'"
              class="text-nowrap"
            >
              <b-avatar
                src="/img/avatars/default.png"
                class="mx-1"
              />
              <span class="text-nowrap">{{ props.row.name }}</span>
            </div>

            <!-- Column: Action -->
            <!-- <span v-else-if="props.column.field === 'jenis'">
              <b-form-select
                v-model="optionsLocal.select_jabatan[props.index]"
                class="form-control"
              >
                <option value="null" disabled=true>Pilih Jabatan</option>
                <option
                  v-for="(option, idx) in optionsLocal.select_jabatan.data"
                  :key="idx"
                  :value="option.id"
                  :title="option.nama || null"
                >
                  {{ option.nama }}
                </option>
              </b-form-select>
            </span> -->

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span>
                <b-button size="sm" variant="primary" @click="tambah(props.index, props.row.id, optionsLocal.select_jabatan[props.index])">
                  <feather-icon
                    icon="PlusIcon"
                  />
                </b-button>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <!-- <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3','5','10']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap "> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template> -->
        </vue-good-table>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="show=false"
            >
              Close
            </b-button>
          </div>
        </template>
      </b-modal>
      <b-toast />
    </b-card-code>
    <template v-slot:overlay>
      <div
        v-if="processing"
        class="text-center p-4 bg-primary text-light rounded"
      >
        <b-icon
          icon="cloud-upload"
          font-scale="4"
        />
        <div class="mb-3">
          Processing...
        </div>
        <b-spinner class="align-middle"></b-spinner>
      </div>
      <div
        v-else
        ref="dialog"
        tabindex="-1"
        role="dialog"
        aria-modal="false"
        aria-labelledby="form-confirm-label"
        class="text-center p-3"
      >
        <p><strong id="form-confirm-label">Apakah Anda Yakin?</strong></p>
        <div class="d-flex">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-danger"
            class="mr-3"
            @click="onCancel"
          >
            Tidak
          </b-button>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="outline-success"
            @click="onOK"
          >
            OK
          </b-button>
        </div>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
// BFormGroup
// BFormInput
// BBadge
import {
  BAvatar, BPagination, BFormSelect, BBadge, BDropdown, BDropdownItem, BRow, BCol, BButton, BModal, BToast, BOverlay,
} from 'bootstrap-vue'
import {
  getUserKegiatan, getListUserKegiatan, insUserKegiatan, delUserKegiatan, ubahStatusUserKegiatan,
} from '@/api/kegiatan'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    // BPagination,
    // BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BButton,
    BModal,
    BToast,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      busy: false,
      show: false,
      koordinator_id: null,
      koordinator_jenis: null,
      processing: null,
      indexMainUser: null,
      rowMainUser: {},
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'User',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Cari Nama',
          },
          sortable: false,
        },
        {
          label: 'Email',
          field: 'biodata.email',
          sortable: false,
        },
        {
          label: 'Status',
          field: 'status_approval_id',
          filterOptions: {
            enabled: true,
            placeholder: 'Cari Status',
          },
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      rowsTotal: 0,
      searchTerm: '',
      pageLengthSub: 10,
      columnsSub: [
        {
          label: 'User',
          field: 'fullName',
          filterOptions: {
            enabled: true,
            placeholder: 'Cari Nama',
          },
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rowsSub: [],
      rowsSubTotal: 0,
      searchTermSub: '',
      optionsLocal: {
        select_jabatan: {
          selected: null,
          data: [
            { id: 2, nama: 'Koordinator' },
            { id: 3, nama: 'Wakil Koordinator' },
          ],
        },
      },
      dataTempUser: [],
      serverParams: {
        columnFilters: {
        },
        sort: {
          field: '',
          type: '',
        },
        page: 1,
        perPage: 10,
      },
      serverParamsMain: {
        columnFilters: {
        },
        sort: {
          field: '',
          type: '',
        },
        page: 1,
        perPage: 10,
      },
      /* eslint-disable global-require */
      // avatar: require('@/assets/images/avatars/default.png'),
      /* eslint-disable global-require */
    }
  },
  async mounted() {
    try {
      this.loadRowsSub()
      this.loadRows()
    } catch (error) {
      // console.log(error.response.data)
    }
  },
  methods: {
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadRowsSub()
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadRowsSub()
    },
    onSortChange(params) {
      this.updateParams({
        sort: params,
      })
      this.loadRowsSub()
    },
    onColumnFilter(params) {
      this.updateParams(params)
      this.loadRowsSub()
    },
    updateParamsMain(newProps) {
      this.serverParamsMain = { ...this.serverParamsMain, ...newProps }
    },
    onPageChangeMain(params) {
      this.updateParamsMain({ page: params.currentPage })
      this.loadRows()
    },
    onPerPageChangeMain(params) {
      this.updateParamsMain({ perPage: params.currentPerPage })
      this.loadRows()
    },
    onSortChangeMain(params) {
      this.updateParamsMain({
        sort: params,
      })
      this.loadRows()
    },
    onColumnFilterMain(params) {
      this.updateParamsMain(params)
      this.loadRows()
    },
    async loadRows() {
      const responseListUserKegiatan = await getListUserKegiatan(this.$route.params.id, 'koordinator', this.serverParamsMain)
      this.rows = responseListUserKegiatan.data.data
      this.rowsTotal = responseListUserKegiatan.data.total
    },
    async loadRowsSub() {
      const response = await getUserKegiatan(this.$route.params.id, 'koordinator', this.serverParams)
      this.rowsSub = response.data.data
      this.rowsSubTotal = response.data.total
    },
    async tambah(index, userId, jabatan) {
      const formData = new FormData()
      formData.append('user_id', userId)
      formData.append('kegiatan_id', this.$route.params.id)
      formData.append('jenis_user_id', 2)

      this.optionsLocal.select_jabatan.data.forEach(element => {
        if (element.id === jabatan) {
          this.koordinator_jenis = element.nama
        }
      })

      try {
        const response = await insUserKegiatan(formData)
        this.makeToast('success', 'Tambah Koordinator Berhasil!')
        // response.data.data[0].jenis_user = this.koordinator_jenis
        // this.rows.push(response.data.data[0])
        // this.rowsSub.splice(index, 1)
        // this.optionsLocal.select_jabatan[index] = ''
        this.loadRowsSub()
        this.loadRows()
      } catch (error) {
        this.makeToast('danger', JSON.stringify(error.response.data))
      }
    },
    setujuUser(index, userid, row) {
      this.busy = true
      this.user_id = userid
      this.indexMainUser = index
      this.actionType = 'setujuUser'

      this.rowMainUser.created_at = row.created_at
      this.rowMainUser.email = row.email
      this.rowMainUser.email_verified_at = row.email_verified_at
      this.rowMainUser.updated_at = row.updated_at
      this.rowMainUser.id = row.id
      this.rowMainUser.jenis = null
      this.rowMainUser.name = row.name
      this.rowMainUser.username = row.username
    },
    tolakUser(index, userid, row) {
      this.busy = true
      this.user_id = userid
      this.indexMainUser = index
      this.actionType = 'tolakUser'

      this.rowMainUser.created_at = row.created_at
      this.rowMainUser.email = row.email
      this.rowMainUser.email_verified_at = row.email_verified_at
      this.rowMainUser.updated_at = row.updated_at
      this.rowMainUser.id = row.id
      this.rowMainUser.jenis = null
      this.rowMainUser.name = row.name
      this.rowMainUser.username = row.username
    },
    deleteKoordinator(index, userid, row) {
      this.busy = true
      this.user_id = userid
      this.indexMainUser = index
      this.actionType = 'deleteUser'

      this.rowMainUser.created_at = row.created_at
      this.rowMainUser.email = row.email
      this.rowMainUser.email_verified_at = row.email_verified_at
      this.rowMainUser.updated_at = row.updated_at
      this.rowMainUser.id = row.id
      this.rowMainUser.jenis = null
      this.rowMainUser.name = row.name
      this.rowMainUser.username = row.username
    },
    onCancel() {
      this.busy = false
    },
    async onOK() {
      try {
        this.busy = false
        this.show = true
        // await delUserKegiatan(this.$route.params.id, this.koordinator_id, 0)
        // this.show = false
        // // this.rowsSub.push(this.rowMainUser)
        // this.rows.splice(this.indexMainUser, 1)
        // this.makeToast('success', 'Hapus Koordinator Berhasil!')
        // this.loadRowsSub()
        if (this.actionType === 'deleteUser') {
          await delUserKegiatan(this.$route.params.id, this.user_id, 0)
          this.show = false
          this.rows.splice(this.indexMainUser, 1)
          this.makeToast('success', 'Hapus Koordinator Berhasil!')
          this.loadRowsSub()
        } else if (this.actionType === 'setujuUser') {
          await ubahStatusUserKegiatan(this.$route.params.id, this.user_id, 2)
          this.makeToast('success', 'Ubah Status Koordinator Berhasil!')
          this.show = false
          this.loadRows()
        } else if (this.actionType === 'tolakUser') {
          await ubahStatusUserKegiatan(this.$route.params.id, this.user_id, 3)
          this.makeToast('success', 'Ubah Status Koordinator Berhasil!')
          this.show = false
          this.loadRows()
        }
      } catch (error) {
        this.makeToast('danger', JSON.stringify(error.response.data))
        this.show = false
      }
    },
    makeToast(variant = null, message = null) {
      this.$bvToast.toast(message, {
        title: 'Kegiatan',
        variant,
        solid: true,
        autoHideDelay: 10000,
      })
    },
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1           : 'light-primary',
        2           : 'light-success',
        3           : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
