<template>
  <b-card>

    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-img
          v-if="optionsLocal.image"
          ref="previewEl"
          :src="optionsLocal.image"
          rounded
          width="125"
        />
        <b-img
          v-else
          rounded
          ref="previewEl"
          :src="avatar"
          width="125"
        />
        <!--/ avatar -->
      </b-media-aside>
      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          Upload
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="optionsLocal.File"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @change="onFileChange"
        />
        <!--/ upload button -->

        <!-- reset -->
        <!-- <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          size="sm"
          class="mb-75 mr-75"
        >
          Reset
        </b-button> -->
        <!--/ reset -->
        <b-card-text class="small">Ukuran 775px x 450px.<br/>Foto JPG, GIF or PNG. Max size of 800kB</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="12">
          <b-form-group
            label="Proyek"
          >
            <v-select
              id="proyek_id"
              :key="selectProyek"
              v-model="proyekId"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="proyekOptions.options"
              item-text="value"
              item-value="label"
              :reduce="(val) => val.value"
              :disabled="disabledProyek"
            />
            <!-- <b-form-select
              v-model="optionsLocal.proyek_id"
              class="form-control"
              @input="(val) => changeProyek(val)"
              :key="selectProyek"
            >
              <option
                value="null"
                disabled="true"
              >
                Pilih Proyek
              </option>
              <option
                v-for="(option, idx) in optionsLocal.select_proyek.data"
                :key="idx"
                :value="option.id"
                :title="option.nama || null"
              >
                {{ option.nama }}
              </option>
            </b-form-select> -->
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Nama Kegiatan"
            label-for="account-username"
          >
            <b-form-input
              v-model="optionsLocal.nama"
              placeholder="Nama Kegiatan"
              name="username"
              class="form-control"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Lokasi"
            label-for="account-name"
          >
            <b-form-input
              v-model="optionsLocal.lokasi"
              name="name"
              placeholder="Lokasi"
            />
          </b-form-group>
        </b-col>
        <!-- birth date -->
        <b-col md="6">
          <b-form-group
            label="Tanggal Mulai"
          >
            <flat-pickr
              v-model="optionsLocal.tanggal_mulai"
              class="form-control"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Tanggal Akhir"
          >
            <flat-pickr
              v-model="optionsLocal.tanggal_akhir"
              class="form-control"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Status"
            label-for="account-name"
          >
            <v-select
              id="statusList"
              v-model="statusPrivate"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOption"
              item-text="id"
              item-value="label"
              @input="(val) => changePrivate(val)"
            />
          </b-form-group>
        </b-col>
        <b-col md="6"
          v-if="showPassword"
        >
          <b-form-group
            label-for="account-new-password"
            label="Masukan Password"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-new-password"
                v-model="optionsLocal.password"
                :type="passwordFieldTypeNew"
                name="new-password"
                placeholder="Masukan Password Baru"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconNew"
                  class="cursor-pointer"
                  @click="togglePasswordNew"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- deskripsi -->
        <b-col cols="12">
          <b-form-group
            label="Deskripsi"
            label-for="deskripsi-area"
          >
            <b-form-textarea
              id="deskripsi-area"
              v-model="optionsLocal.deskripsi"
              rows="4"
              placeholder="Deskripsi kegiatan disini..."
            />
          </b-form-group>
        </b-col>
        <!-- output -->
        <b-col cols="12">
          <b-form-group
            label="Output"
            label-for="output-area"
          >
            <b-form-textarea
              id="output-area"
              v-model="optionsLocal.output"
              rows="4"
              placeholder="Output kegiatan disini..."
            />
          </b-form-group>
        </b-col>
        <b-col sm="6"
          v-if="$can('admin-kegiatan', 'tag')"
        >
          <b-form-group
            label="Tag Admin"
            label-for="account-name"
          >
            <v-select
              id="tag_admin"
              v-model="tag_admin"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="tagAdminOptions"
              item-text="value"
              item-value="label"
              :reduce="(val) => val.value"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Tag Public"
            label-for="account-name"
          >
            <v-select
              id="tag_public"
              v-model="tag_public"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="tagPublicOptions"
              :multiple="true"
              item-text="value"
              item-value="label"
              :reduce="(val) => val.value"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12"
          v-if="$can('tabkegiatan', 'kegiatan')"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click="simpan"
          >
            Simpan
          </b-button>
          <!-- <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="resetForm"
          >
            Reset
          </b-button> //-->
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormTextarea, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BImg, BFormSelect, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import {
  getKegiatan,
} from '@/api/kegiatan'
import {
  getAllProyek,
} from '@/api/proyek'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BFormTextarea,
    // BFormSelect,
    flatPickr,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    tagAdminOptions: {},
    tagPublicOptions: {},
  },
  data() {
    return {
      proyekOptions: {
        selected: 'id',
        options: [
        ],
      },
      disabledProyek: false,
      selectProyek: null,
      tag_admin: null,
      tag_public: null,
      optionsLocal: {
        select_proyek: {
          selected: null,
        },
      },
      date: null,
      localData: [],
      /* eslint-disable global-require */
      avatar: require('@/assets/images/pages/default.png'),
      /* eslint-disable global-require */
      passwordFieldTypeNew: 'password',
      showPassword: false,
      statusOption: [
        {
          id: 0,
          label: 'Publik  Approval',
        },
        {
          id: 1,
          label: 'Privat  Approval',
        },
        {
          id: 2,
          label: 'General',
        },
        {
          id: 3,
          label: 'Closed',
        },
      ],
      proyekId: 0,
      statusPrivate: {},
    }
  },
  computed: {
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  async mounted() {
    if (this.$route.params.id) {
      const response = await getKegiatan(this.$route.params.id)
      this.localData = response.data.data
      this.optionsLocal.proyek_id = this.localData.proyek_id
      if (!this.$can('kegiatan', 'proyek')) {
        this.disabledProyek = true
      }
      this.optionsLocal.nama = this.localData.nama
      this.optionsLocal.lokasi = this.localData.lokasi
      this.optionsLocal.deskripsi = this.localData.deskripsi
      this.optionsLocal.output = this.localData.output
      this.optionsLocal.tanggal_mulai = this.localData.tanggal_mulai
      this.optionsLocal.tanggal_akhir = this.localData.tanggal_akhir
      this.optionsLocal.image = `${process.env.VUE_APP_API_URL}/kegiatan/download/${this.localData.img}`
      this.optionsLocal.img = this.localData.img
      this.optionsLocal.password = this.localData.password
      if (this.localData.private != null) {
        const isPrivate = this.localData.private
        switch (isPrivate) {
          case 0:
          default:
            this.statusPrivate = { id: 0, label: 'Publik Approval' }
            break
          case 1:
            this.statusPrivate = { id: 1, label: 'Private Approval' }
            break
          case 2:
            this.statusPrivate = { id: 2, label: 'General' }
            break
          case 3:
            this.statusPrivate = { id: 3, label: 'Closed' }
            break
        }
        // this.localData.private
      }
      if (this.localData.proyek_id !== null) {
        this.proyekId = this.localData.proyek_id
      }
      if (this.localData.tag_admin !== null) {
        this.tag_admin = this.localData.tag_admin
      }
      if (this.localData.tag_public !== null) {
        this.tag_public = this.localData.tag_public
      }
      if (!this.localData.private) {
        this.optionsLocal.private = {
          id: 0,
          label: 'Publik',
        }
        this.showPassword = false
      } else if (this.localData.private === 1) {
        this.optionsLocal.private = {
          id: 1,
          label: 'Privat',
        }
        this.showPassword = true
      } else if (this.localData.private === 2) {
        this.optionsLocal.private = {
          id: 2,
          label: 'General',
        }
        this.showPassword = false
      } else if (this.localData.private === 3) {
        this.optionsLocal.private = {
          id: 3,
          label: 'Closed',
        }
        this.showPassword = true
      }
    }
    try {
      const responseProyek = await getAllProyek({ byme: true })
      this.mappingSelectOption(responseProyek.data.data, this.proyekOptions.options)
      this.selectProyek += 1
      if (responseProyek.data.data.length === 0) {
        const responseProyekDefault = []
        responseProyekDefault.push(this.localData.select_proyek)
        this.mappingSelectOption(responseProyekDefault, this.proyekOptions.options)
        // // this.optionsLocal.select_proyek.data.push(this.localData.select_proyek)
        // // this.optionsLocal.select_proyek.data = this.optionsLocal.select_proyek
        this.selectProyek += 1
        // console.log(this.proyekOptions.options)
      }
      // console.log(this.optionsLocal.select_proyek)
      // his.optionsLocal.select_proyek.selected = null
    } catch (error) {
      // console.log(error.response)
    }
    // console.log(this.optionsLocal.select_proyek)
  },
  methods: {
    changeProyek(value) {
      this.proyekId = value
    },
    changePrivate(value) {
      if (value.id === 1 || value.id === 3) {
        this.showPassword = true
      } else {
        this.showPassword = false
      }
      // console.log(value)
      // this.optionsLocal.private = value.id
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    resetForm() {
      this.optionsLocal = []
    },
    simpan() {
      if (this.$route.params.id) {
        this.optionsLocal.kegiatan_id = this.$route.params.id
      }
      this.localData = this.optionsLocal
      this.localData.proyek_id = this.proyekId
      this.localData.tag_admin = this.tag_admin
      this.localData.tag_public = this.tag_public
      this.localData.private = this.statusPrivate
      // if (this.optionsLocal.private.id) {
      // this.localData.private = this.optionsLocal.private.id
      // }
      this.$emit('clicked', this.localData)
    },
    onFileChange(e) {
      const file = e.target.files[0]
      this.optionsLocal.img = URL.createObjectURL(file)
      this.optionsLocal.image = this.optionsLocal.img
    },
    mappingSelectOption(master, name) {
      if (master.length > 0) {
        master.forEach(element => {
          name.push({
            label: element.nama,
            value: element.id,
          })
        })
      }
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    return {
      refInputEl,
      previewEl,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
